import { createStore } from 'vuex';
import auth from './modules/auth';
import countries from './modules/countries';
import sheets from './modules/sheets';
import loading from './modules/loading';
import error from './modules/error';
import user from './modules/user';

const store = createStore({
  modules: {
    auth,
    countries,
    error,
    loading,
    sheets,
    user,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
});

// Initialize store with local storage
const storedEmail = localStorage.getItem('email');
const storedJwt = localStorage.getItem('jwt');
if (storedEmail && storedJwt) {
  store.commit('auth/SET_AUTH', { email: storedEmail, jwt: storedJwt });
}

export default store;
