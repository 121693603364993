<template>
  <v-main class="d-flex align-center justify-center fill-height">
    <v-container
      fluid
      class="d-flex align-center justify-center"
    >
      <v-alert
        type="error"
        dismissible
      >
        {{ errorMessage }}
      </v-alert>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ErrorScreen',
  computed: {
    ...mapGetters('error', ['errorMessage']),
  },
};
</script>

  <style scoped>
  .v-main {
    background-color: #f5f5f5;
  }
  </style>
