<template>
  <v-main class="d-flex align-center justify-center fill-height">
    <v-container
      fluid
      class="d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'LoadingScreen',
};
</script>

  <style scoped>
  .v-main {
    background-color: #f5f5f5;
  }
  </style>
