const authState = {
  email: null,
  jwt: null,
};

const mutations = {
  SET_AUTH(state, { email, jwt }) {
    state.email = email;
    state.jwt = jwt;
  },
  CLEAR_AUTH(state) {
    state.email = null;
    state.jwt = null;
  },
};

const actions = {
  async login({ commit }, credentials) {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_LOGIN_PATH}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      const data = await response.json();

      if (data.token) {
        commit('SET_AUTH', { email: credentials.email, jwt: data.token });
        localStorage.setItem('email', credentials.email);
        localStorage.setItem('jwt', data.token);
      } else {
        throw new Error('Invalid login');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      commit('CLEAR_AUTH');
      localStorage.removeItem('email');
      localStorage.removeItem('jwt');
      throw new Error('Invalid login');
    }
  },
  logout({ commit }) {
    commit('CLEAR_AUTH');
    localStorage.removeItem('email');
    localStorage.removeItem('jwt');
  },
};

const getters = {
  isAuthenticated: (state) => !!state.jwt,
  getEmail: (state) => state.email,
  getJwt: (state) => state.jwt,
};

export default {
  namespaced: true,
  state: authState,
  mutations,
  actions,
  getters,
};
