const errorState = {
  errorMessage: null,
};

const mutations = {
  SET_ERROR(state, message) {
    state.errorMessage = message;
  },
  CLEAR_ERROR(state) {
    state.errorMessage = null;
  },
};

const getters = {
  errorMessage: (state) => state.errorMessage,
  hasError: (state) => !!state.errorMessage,
};

export default {
  namespaced: true,
  state: errorState,
  mutations,
  getters,
};
