import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import CalculatorView from '../views/CalculatorView.vue';
import LoginView from '../views/LoginView.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: CalculatorView,
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    redirect: () => {
      const loggedIn = store.getters['auth/isAuthenticated'];
      if (loggedIn) {
        return '/calculator';
      }
      return '/login';
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const loggedIn = store.getters['auth/isAuthenticated'];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next('/login');
    } else {
      store.commit('loading/SET_LOADING', true);
      await store.dispatch('sheets/fetchSheets');
      await store.dispatch('countries/fetchCountries');
      await store.dispatch('user/fetchUser');
      store.commit('loading/SET_LOADING', false);
      next();
    }
  } else {
    next();
  }
});

export default router;
