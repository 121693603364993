<template>
  <v-row>
    <v-col>
      <v-card
        v-if="results.length"
        elevation="0"
      >
        <v-toolbar
          density="compact"
          color="blue-grey-darken-1"
        >
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              prepend-icon="mdi-download"
              @click="showDownloadDialog"
            >
              Pobierz wyniki
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              sm="4"
              class="d-flex pa-2"
            >
              <div
                style="width: 100%"
                class="ma-2"
              >
                <v-img :src="getSchemaImagePath(result.schema)" />
                <v-divider class="my-4" />
                <v-table density="compact">
                  <tbody>
                    <tr>
                      <td>{{ result.firstPart }}</td>
                      <td class="text-right">
                        {{ result.firstPartLength.toFixed(2) }}
                      </td>
                      <td>[m]</td>
                    </tr>
                    <tr v-if="result.secondPart != null">
                      <td>{{ result.secondPart }}</td>
                      <td class="text-right">
                        {{ result.secondPartLength.toFixed(2) }}
                      </td>
                      <td>[m]</td>
                    </tr>
                    <tr>
                      <td rowspan="2">
                        P
                      </td>
                      <td class="text-right">
                        {{ result.load.toFixed(2) }}
                      </td>
                      <td>[kg]</td>
                    </tr>
                    <tr>
                      <td class="text-right">
                        {{ result.loadInKN.toFixed(2) }}
                      </td>
                      <td>[kN]</td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="d-flex"
            >
              <v-tooltip
                location="top center"
              >
                <template #activator="{ props }">
                  <v-img
                    v-bind="props"
                    :src="getInfoImagePath()"
                    class="ma-2"
                  />
                </template>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus
                </div>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="d-flex"
            >
              <v-tooltip
                location="top center"
              >
                <template #activator="{ props }">
                  <v-img
                    v-bind="props"
                    :src="get3dImagePath(result.schema)"
                    class="ma-2"
                  />
                </template>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="downloadDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">
          Pobierz wyniki
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="fileName"
              name="fileName"
              label="Nazwa pliku"
              required
              autocomplete="off"
              :rules="fileNameRules"
              variant="outlined"
            >
              <template #append-inner>
                <v-chip size="small">
                  .pdf
                </v-chip>
              </template>
            </v-text-field>
            <v-radio-group
              v-model="pdfType"
              label="Typ eksportu"
            >
              <v-radio
                label="Skrócony"
                value="simple"
              />
              <v-radio
                label="Rozszerzony"
                value="extended"
              />
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-grey-darken-1"
            text
            variant="outlined"
            @click="downloadDialog = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="blue-grey-darken-1"
            text
            :loading="isDownloading"
            :disabled="isDownloading"
            variant="outlined"
            @click="downloadFile"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            Pobierz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    results: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schemaImages: this.importAllImages(require.context('@/assets/images/schemas', false, /\.png$/)),
      threeDImages: this.importAllImages(require.context('@/assets/images/schemas/3d', false, /\.png$/)),
      infoImage: this.importAllImages(require.context('@/assets/images/schemas/info', false, /\.png$/)),
      downloadDialog: false,
      fileName: '',
      isDownloading: false,
      fileNameRules: [
        (v) => !!v || 'Nazwa pliku jest wymagana',
        (v) => !/[/:*?"<>|]/.test(v) || 'Nazwa pliku zawiera niedozwolone znaki',
      ],
      pdfType: 'extended',
    };
  },
  computed: {
    result() {
      if (this.inputData.schemas) {
        // Assuming schemas is now a single value, filter based on equality check
        console.log('Result:', this.results.filter((result) => result.schema === this.inputData.schemas)[0]);
        return this.results.filter((result) => result.schema === this.inputData.schemas)[0];
      }

      return this.results[0];
    },
  },
  methods: {
    importAllImages(r) {
      const images = {};
      r.keys().forEach((key) => {
        const number = key.match(/(\d+)\.png$/)[1];
        images[number] = r(key);
      });
      return images;
    },
    getSchemaImagePath(i) {
      return this.schemaImages[i];
    },
    get3dImagePath(i) {
      return this.threeDImages[i];
    },
    getInfoImagePath() {
      return this.infoImage[0];
    },
    showDownloadDialog() {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}-${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      this.fileName = `Hanger-wyniki-${formattedDate}`;
      this.downloadDialog = true;
    },
    async downloadFile() {
      const valid = await this.$refs.form.validate();
      if (valid) {
        this.isDownloading = true;

        try {
          const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/hanger/download`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
            body: JSON.stringify({
              length: this.inputData.l,
              load: this.inputData.q,
              sheetTypeId: this.inputData.sheet,
              maxLoad: this.inputData.pmax,
              thickness: parseFloat(this.inputData.thickness.replace(',', '.')),
              filename: this.fileName,
              type: this.pdfType,
            }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.fileName}.pdf`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('There was an error downloading the file:', error);
        } finally {
          this.isDownloading = false;
          this.downloadDialog = false;
        }
      }
    },
  },
};
</script>
