<template>
  <v-app>
    <LoadingScreen v-if="isLoading" />
    <ErrorScreen v-else-if="hasError" />
    <router-view v-else />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingScreen from './components/LoadingScreen.vue';
import ErrorScreen from './components/ErrorScreen.vue';

export default {
  components: {
    LoadingScreen,
    ErrorScreen,
  },
  computed: {
    ...mapGetters('loading', ['isLoading']),
    ...mapGetters('error', ['hasError']),
  },
};
</script>

<style>
#app {
  height: 100%;
}
</style>
