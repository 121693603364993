const loadingState = {
  loading: false,
};

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  isLoading: (state) => state.loading,
};

export default {
  namespaced: true,
  state: loadingState,
  mutations,
  getters,
};
