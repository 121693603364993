<template>
  <v-main class="bg-grey-lighten-2">
    <v-container
      class="d-flex flex-column align-center justify-center fill-height"
    >
      <v-card
        class="pa-5"
        min-width="350"
      >
        <v-card-title class="headline">
          Login
        </v-card-title>
        <v-form
          v-model="valid"
          @submit.prevent="loginAction"
        >
          <v-alert
            v-if="error"
            v-model="showError"
            type="error"
            dismissible
            class="mb-4"
          >
            {{ error }}
          </v-alert>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email"
            required
          />
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            type="password"
            required
          />
          <v-card-actions>
            <v-btn
              block
              :loading="loading"
              color="primary"
              type="submit"
              :disabled="!valid"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      valid: false,
      loading: false,
      error: '',
      showError: true,
      emailRules: [
        (v) => !!v || 'Adres email jest wymagany',
        (v) => /.+@.+\..+/.test(v) || 'Podaj poprawny adres email',
      ],
      passwordRules: [
        (v) => !!v || 'Hasło jest wymagane',
      ],
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async loginAction() {
      try {
        this.loading = true;
        this.error = ''; // Reset error message
        await this.login({ username: this.email, password: this.password });
        this.$router.push('/calculator');
      } catch (error) {
        console.error('Login failed:', error);
        this.error = 'Incorrect email or password. Please try again.';
        this.showError = true; // Show the alert
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
