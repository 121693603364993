<template>
  <v-app>
    <v-app-bar
      color="blue-grey-lighten-1"
    >
      <v-app-bar-title>HANGer</v-app-bar-title>
      <v-spacer />
      <user-menu-component />
    </v-app-bar>

    <v-main class="bg-grey-lighten-2">
      <v-container fluid>
        <form-input-component
          :q="inputData.q"
          :l="inputData.l"
          :sheet="inputData.sheet"
          :pmax="inputData.pmax"
          @update-form-data="handleFormDataUpdate"
          @invalid-form-data="handleInvalidFormData"
        />
        <result-component
          v-if="valid"
          :loading="loading"
          :input-data="inputData"
          :results="results"
        />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import FormInputComponent from '../components/FormInputComponent.vue';
import ResultComponent from '../components/ResultComponent.vue';
import UserMenuComponent from '../components/UserMenuComponent.vue';

export default {
  components: {
    FormInputComponent,
    ResultComponent,
    UserMenuComponent,
  },
  data() {
    return {
      inputData: {
        q: 0.36,
        l: 3.45,
        pmax: 0.15,
        country: null,
        thickness: null,
        schemas: [],
        sheet: null,
      },
      valid: false,
      results: [],
      loading: false,
    };
  },
  methods: {
    async handleFormDataUpdate(formData) {
      this.inputData.q = formData.q;
      this.inputData.l = formData.l;
      this.inputData.sheet = formData.sheet;
      this.inputData.pmax = formData.pmax;
      this.inputData.country = formData.country;
      this.inputData.thickness = formData.thickness;
      this.inputData.schemas = formData.schemas;
      this.valid = true;
      this.results = [];

      if (this.valid) {
        this.loading = true;
        await this.fetchResults();
      }
    },
    handleInvalidFormData() {
      this.results = [];
      this.valid = false;
    },
    async fetchResults() {
      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/hanger/calculate`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
          body: JSON.stringify({
            length: this.inputData.l,
            load: this.inputData.q,
            sheetTypeId: this.inputData.sheet,
            maxLoad: this.inputData.pmax,
            thickness: parseFloat(this.inputData.thickness.replace(',', '.')),
          }),
        });
        this.results = await response.json();
        console.log('Results:', this.results);
      } catch (error) {
        console.error('Error fetching results:', error);
        this.results = [];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
