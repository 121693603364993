const sheetsState = {
  sheets: [],
  loading: false,
};

const mutations = {
  SET_SHEETS(state, sheets) {
    state.sheets = sheets;
  },
};

const actions = {
  async fetchSheets({ commit }) {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/sheets`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });
      const data = await response.json();
      commit('SET_SHEETS', data);
    } catch (error) {
      commit('error/SET_ERROR', 'Error while fetching data from API', { root: true });
      console.error('Error fetching sheets:', error);
    }
  },
};

const getters = {
  sheets: (state) => state.sheets,
};

export default {
  namespaced: true,
  state: sheetsState,
  mutations,
  actions,
  getters,
};
