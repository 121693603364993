const userState = {
  user: null,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER(state) {
    state.user = null;
  },
};

const actions = {
  async fetchUser({ commit }) {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_DOMAIN}/api/user`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data) {
        commit('SET_USER', data);
      } else {
        throw new Error('Invalid user data');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  },
};

const getters = {
  getUser: (state) => state.user,
  getUserCountry: (state) => state.user?.country?.name,
};

export default {
  namespaced: true,
  state: userState,
  mutations,
  actions,
  getters,
};
