<template>
  <v-menu
    min-width="200px"
    rounded
  >
    <template #activator="{ props }">
      <v-btn
        icon
        v-bind="props"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <div class="mx-auto text-center">
          <v-avatar
            color="brown"
          >
            <span class="text-h6">{{ userInitials }}</span>
          </v-avatar>
          <h3>{{ user.name }}</h3>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-divider class="my-3" />
          <v-btn
            variant="text"
            @click="handleLogout"
          >
            Wyloguj
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {

  name: 'UserMenuComponent',
  computed: {
    ...mapGetters('user', ['getUser']),
    user() {
      return this.getUser;
    },
    userInitials() {
      if (!this.user.name) return '';
      const nameParts = this.user.name.split(' ');
      const initials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('');

      return initials;
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push('/login');
      });
    },
  },
};
</script>
